import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseUrl = import.meta.env.VITE_PUBLIC_APP_API;
const x_api_key = import.meta.env.VITE_X_API_KEY;

const ApplyVacancy = (props) => {
  const { t } = useTranslation('navbar_translation', 'components_header_trn');
  const jobCode = props || 'Not Available';
  const [fileInput, setFileInput] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    files: fileInput,
    subject: jobCode?.vacancyCode,
    message: 'Apply On This job',
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Full Name is required';
    if (!formData.email) {
      errors.email = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email Address is invalid';
    }
    if (!formData.phone) {
      errors.phone = 'Phone Number is required';
    } else if (formData.phone.length < 10) {
      errors.phone = 'Phone Number is invalid';
    }
    return errors;
  };

  const notify = () => toast('Successfully applied!');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    const dataToSubmit = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    try {
      await axios.post(
        `${baseUrl}/portal-interactions/create-contact-us`,
        dataToSubmit,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': x_api_key,
          },
        }
      );

      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: jobCode,
        message: 'Apply On This job',
      });
      setFileInput(null);

      document.getElementById('file-input').value = '';

      notify();
    } catch (error) {
      console.error('Submission error:', error.response?.data);
      setErrors({ message: 'Submission failed, please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='container-fluid py-4 mt-1'>
      <div className='col-lg-12 wow fadeIn' data-wow-delay='.5s'>
        <form onSubmit={handleSubmit}>
          <div className='rounded contact-form'>
            <h4 className='mb-4 txt-primary text-uppercase'>
              {t('Apply Here', { ns: 'components_header_trn' })}
            </h4>
            <div className='mb-4'>
              <input
                type='text'
                className='form-control py-3'
                placeholder={t('Full Name', { ns: 'components_header_trn' })}
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
              {errors.name && <p className='text-danger'>{errors.name}</p>}
            </div>
            <div className='mb-4'>
              <input
                type='phone'
                className='form-control border-1 py-3'
                placeholder={t('Phone Number', { ns: 'components_header_trn' })}
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
              {errors.phone && <p className='text-danger'>{errors.phone}</p>}
            </div>
            <div className='mb-4'>
              <input
                type='email'
                className='form-control border-1 py-3'
                placeholder={t('Email Address', {
                  ns: 'components_header_trn',
                })}
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {errors.email && <p className='text-danger'>{errors.email}</p>}
            </div>
            <div className='mb-4'>
              <label>{t('CV / Resume', { ns: 'components_header_trn' })}</label>
              <input
                type='file'
                id='file-input' // Add an ID to the file input
                className='form-control border-1 py-3'
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFileInput(file); // Update file input state
                }}
                accept='.pdf,.doc,.docx'
              />
              {errors.files && <p className='text-danger'>{errors.files}</p>}
            </div>

            <div className='text-start'>
              <button
                className='btn bg-primary text-white py-3 px-5 justify-content-center'
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>Loading...</span>
                ) : (
                  t(`SUBMIT`, { ns: 'components_header_trn' })
                )}
              </button>
            </div>
          </div>
        </form>
        <ToastContainer position='bottom-right' style={{ color: 'white' }} />
      </div>
    </div>
  );
};

export default ApplyVacancy;
